<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Demandes de support</h2>

        <div class="actions">
          <button class="btn btn-outline-primary btn-sm" @click="openRequestModal">
            Nouvelle demande
          </button>
        </div>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <pagination-filter
          state="supportRequests"
          placeholder-text-filter="Chercher une demande"
          :display-text-filter="true"
          :display-status-filter="true"
          :list-status-filter="getStatusList"
          :display-reset="true"
          @changePage="changePage"
        />

        <div class="card mb-4 support-requests">
          <div class="card-body p-0">
            <collection-table
              :cols="cols"
              :list-items="requests"
              :show-actions="false"
              :sort="sortBy"
              :sort-direction="sortDirection"
              :only-display-sort-col="true"
              no-top-border
              hover
              :link="(item) => `/support/requests/${item.id}`"
              :empty-img-src="require('@/assets/images/icons/setting.svg')"
              empty-message="Vous n'avez aucune demande de support"
              @sortBy="handleSortBy"
            ></collection-table>

            <div v-if="paginatedData.data.length > 0" class="drawer-footer d-flex justify-content-center align-items-center">
              <pagination state="supportRequests"
                          :current-page="page"
                          :pages="paginatedData.pageCount"
                          :range="2"
                          :display-per-page="true"
                          @change="changePage" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import formatsDate from '@/mixins/formatsDate'
import formatsCurrency from '@/mixins/formatsCurrency'
import userData from '@/mixins/user-data'
import http from '@/utils/http'
import Pagination from '@/components/common/Pagination'
import PaginationFilter from '@/components/common/PaginationFilter'
import pagination from '@/mixins/pagination'

export default {
  components: {
    Pagination,
    PaginationFilter
  },
  mixins: [formatsDate, userData, formatsCurrency, pagination],
  computed: {
    getStatusList () {
      return [
        {
          value: 'open',
          label: 'Ouverte'
        },
        {
          value: 'close',
          label: 'Fermée'
        }
      ]
    },
    cols () {
      return [
        {
          header: 'Référence',
          property: 'reference',
          isSortable: true
        },
        {
          header: 'Ouverte le',
          property: 'createdAt',
          headerClass: 'col-date',
          method: (item) => {
            return this.$longDateTime(item.createdAt)
          },
          isSortable: true
        },
        {
          header: 'Statut',
          property: 'isClosed',
          method: (item) => {
            return item.isClosed ? 'Fermée' : 'Ouverte'
          },
          isSortable: true
        }
      ]
    },
    requests () {
      return this.paginatedData.data
    }
  },
  methods: {
    async changePage (page) {
      await this.fetchRequests(page)
    },
    async fetchRequests (page) {
      await this.$store.dispatch('supportRequests/fetch', { page, storeId: this.currentStoreId, userId: this.userId })
    },
    async handleSortBy (evt) {
      this.sortBy = evt.property
      this.sortDirection = evt.direction

      await this.fetchRequests()
    },
    async openRequestModal () {
      try {
        await this.$modal.openFormModal({
          title: 'Demande de support',
          fields: [
            {
              name: 'message',
              label: 'Votre message',
              type: 'textarea',
              placeholder: 'Comment pouvons-nous vous aider aujourd\'hui ?'
            }
          ],
          confirmLabel: 'Envoyer',
          cancelLabel: 'Annuler',
          disableEnterKey: true,
          onSubmit: async ({ values, confirm, setErrors }) => {
            setErrors({})

            if (!values.message) {
              setErrors({
                message: ['Ce champ est requis.']
              })
              return
            }

            await http.post('/v1/support/requests', {
              message: values.message,
              storeId: this.currentStoreId
            })

            confirm()

            this.$toasted.success('Votre demande a bien été envoyée!')
            this.changePage(1)
          }
        })
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    }
  },
  created () {
    this.instance = 'supportRequests'
    this.fetchRequests()
  }
}
</script>

<style lang="scss">
.support-requests {
  .col-date {
    width: 220px;
    min-width: 220px;
    max-width: 220px;
  }
}
</style>
