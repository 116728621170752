<template>
  <app-layout>
    <div class="px-4 px-sm-5 container">
      <async-object
        should-fetch
        :fetch-method="fetchData"
      >
        <div class="heading d-flex justify-content-between mb-5">
          <h2>Détail de la demande</h2>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <router-link class="btn btn-link btn-sm" :to="{ name: 'supportRequests.index' }" active-class="active">
                <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
              </router-link>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-9 col-xl-9">
            <div class="card mb-4">
              <div class="card-body">
                <dl class="row">
                  <dt class="col-4">Demande ouverte le</dt>
                  <dd class="col-8">{{ supportRequest ? $longDateTime(supportRequest.createdAt) : '-' }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Dernier message</dt>
                  <dd class="col-8">{{ lastMessage ? $longDateTime(lastMessage.createdAt) : '-' }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Statut</dt>
                  <dd class="col-8">{{ statusText }}</dd>
                </dl>
              </div>
            </div>

            <h3 class="subtitle my-4">Messages</h3>

            <div class="mb-4">
              <message-composer @submit="sendMessage" />
            </div>

            <div class="card mb-4" v-for="message in orderedMessages" :key="message.id">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="author">
                    <strong>{{ getMessageAuthor(message) }}</strong>
                  </div>
                  <div class="date">
                    <small>{{ getMessageDate(message) }}</small>
                  </div>
                </div>

                <div class="message">{{ message.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import { orderBy, get } from 'lodash-es'
import MessageComposer from '@/components/common/MessageComposer'
import userData from '@/mixins/user-data'
import formatsDate from '@/mixins/formatsDate'
import http from '@/utils/http'

export default {
  mixins: [userData, formatsDate],
  components: {
    MessageComposer
  },
  computed: {
    supportRequest () {
      return this.$store.getters['supportRequests/getDetailsById'](this.$route.params.id)
    },
    orderedMessages () {
      return this.supportRequest ? orderBy(this.supportRequest.messages, ['createdAt'], ['desc']) : []
    },
    lastMessage () {
      return this.orderedMessages.length ? this.orderedMessages[0] : null
    },
    statusText () {
      if (!this.supportRequest) {
        return ''
      }

      return this.supportRequest.isClosed ? 'Fermée' : 'Ouverte'
    }
  },
  methods: {
    fetchData () {
      this.$store.dispatch('supportRequests/fetchById', {
        id: this.$route.params.id,
        storeId: this.currentStoreId
      })
    },
    getMessageAuthor (message) {
      if (message.authorType === 'support') {
        return 'Équipe support'
      }

      return `${get(message, 'author.firstName')} ${get(message, 'author.lastName')}`
    },
    getMessageDate (message) {
      return this.$shortDateTime(message.createdAt)
    },
    async sendMessage (message, onSuccess, onError) {
      try {
        await http.post(`/v1/support/requests/${this.$route.params.id}/messages`, {
          message
        })

        this.fetchData()
        onSuccess(false)

        this.$toasted.success('Votre message a bien été envoyé.')
      } catch {
        onError()
        this.$toasted.error('Erreur lors de l\'envoi. Veuillez réessayer.')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 1.2rem;
}

.message {
  white-space: pre-wrap;
}
</style>
